import React, { useState } from "react";
import styled from "styled-components";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "gatsby";
import logo from "../assets/trustflight-logo-blue.svg";
import FBLogo from "../assets/fb.svg";
import TwitterLogo from "../assets/twitter.svg";
import LILogo from "../assets/linkedin.svg";
import styles from "./footer.module.less";

const aboutListContent = [
  { name: "Technology", link: "technology" },
  { name: "About TrustFlight", link: "about" },
  { name: "Culture", link: "culture" },
  { name: "News", link: "news" },
  { name: "Careers", link: "careers" },
  { name: "Legal", link: "legal" },
];

const productListContent = [
  { name: "Tech Log", link: "techlog" },
  { name: "Task Cards", link: "taskcards" },
  { name: "Digital Engine Log", link: "logbook" },
  { name: "Centrik", link: "centrik" },
  { name: "MEL Manager", link: "mel-manager" },
];

const servicesListContent = [{ name: "CAMO", link: "camo" }];

const locationListContent = [
  {
    name: "Leamington",
    phone: "+44 (0) 330 229 0219",
    email: "info@trustflight.com",
    address: "34 Hamilton Terrace,",
    address2: "Leamington Spa,",
    address3: "CV32 4LY",
    address4: "United Kingdom",
    link: "Leamington Spa, UK",
  },
  {
    name: "Biggin Hill",
    phone: "+44 (0) 1959 543204",
    email: "info@centrik.net",
    address: "Building 510",
    address2: "Churchill Way Biggin Hill Airport",
    address3: "Kent, TN16 3BN",
    address4: "United Kingdom",
    link: "Biggin Hill, UK",
  },
  {
    name: "Edinburgh",
    phone: "+44 (0) 131 556 3445",
    email: "info@trustflight.com",
    address: "No. 2 Lochrin Square,",
    address2: "96 Fountainbridge,",
    address3: "Edinburgh, EH3 9QA",
    address4: "United Kingdom",
    link: "Edinburgh, UK",
  },
  {
    name: "Jacksonville",
    phone: "+1 (813) 579 5445",
    email: "info@trustflight.com",
    address: "Suite 3159,",
    address2: "1601-1 N Main St,",
    address3: "Jacksonville,",
    address4: "FL 32206",
    link: "Jacksonville, USA",
  },
  {
    name: "Jersey",
    phone: "+44 (0) 1534 486830",
    email: "info@trustflight.com",
    address: "1st Floor Forum 4,",
    address2: "Grenville Street,",
    address3: "St Helier,",
    address4: "Jersey, JE2 4UF",
    link: "Jersey, JE",
  },
  {
    name: "Luton",
    phone: "+44 (0) 330 229 0289",
    email: "info.camo@trustflight.com",
    address: "Basepoint,",
    address2: "Great Marlings,",
    address3: "Luton, LU2 8DL",
    address4: "United Kingdom",
    link: "Luton, UK",
  },
  {
    name: "Vancouver",
    email: "info@trustflight.com",
    address: "Suite 501,",
    address2: "535 Thurlow St,",
    address3: "Vancouver, BC,",
    address4: "V6E 3L2",
    link: "Vancouver, CA",
  },
];

const listCreator = (array) => {
  const list = array.map((item) => {
    return (
      <Link className={styles.footerLink} to={`/${item.link}`} key={item.name}>
        {item.name}
      </Link>
    );
  });
  return <div className={styles.footerList}>{list}</div>;
};

const StyledDiv = styled("div")`
  width: 100%;
  background-image: linear-gradient(to bottom, #f8fcff 0%, #ffffff);
  border-top: 1px #ecf1f6 solid;
`;

const Header = styled("h3")`
  width: 78px;
  height: 19px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.06px;
  color: #242d41;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 20px;
  }
`;

const AddressHeader = styled("span")`
  font-family: Open Sans;
  font-size: 14px;
  letter-spacing: 0.05px;
  font-weight: 600;
  line-height: 2;
  color: rgba(36, 45, 65, 0.8);
`;

const AddressText = styled("span")`
  font-family: Open Sans;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.71;
  letter-spacing: 0.05px;
  color: rgba(36, 45, 65, 0.8);
  min-width: 135px;
  transition: all 0.5s;
  a {
    color: rgba(36, 45, 65, 0.8);
    &:hover {
      color: #1e5bb5;
    }
  }
`;

const FinalText = styled("span")`
  opacity: 0.7;
  font-family: Open Sans;
  font-size: 12px;
  line-height: 2;
  letter-spacing: 0.05px;
  color: rgba(36, 45, 65, 0.8);
`;

const StyledContentDiv = styled("div")`
  margin: 0;
`;

const AddressContentDiv = styled("div")`
  margin-right: 10px;
  @media screen and (max-width: 991px) {
    margin-right: 0;
  }
`;

const StyledImg = styled("img")`
  margin-right: 30px;
`;

export default () => {
  const [selectedLocation, setSelectedLocation] = useState(
    locationListContent[0]
  );

  const addressList = locationListContent.map((item) => (
    <button
      type="button"
      className={
        item === selectedLocation
          ? `${styles.addressLinkButton} ${styles.activeLink}`
          : styles.addressLinkButton
      }
      onClick={() => setSelectedLocation(item)}
    >
      {item.link}
    </button>
  ));
  return (
    <StyledDiv>
      <Container>
        <Row className={`${styles.innerStyleDiv} justify-content-md-center`}>
          <Col xl={10}>
            <Row className={styles.infoDiv}>
              <Col lg={2} md={3}>
                <div className={styles.imageDiv}>
                  <img src={logo} alt="footerlogo" />
                </div>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <StyledContentDiv className={styles.aboutList}>
                  <Header>About</Header>
                  {listCreator(aboutListContent)}
                </StyledContentDiv>
              </Col>
              <Col lg={2} md={4} xs={6}>
                <StyledContentDiv>
                  <Header>Products</Header>
                  {listCreator(productListContent)}
                  <Header>Services</Header>
                  {listCreator(servicesListContent)}
                </StyledContentDiv>
              </Col>
              <Col lg={{ span: 3, offset: 0 }} md={{ span: 4 }}>
                <StyledContentDiv className={styles.contactSection}>
                  <Header>Our Offices</Header>
                  <div className={styles.contactInfoDiv}>
                    <AddressContentDiv className={styles.addressDiv}>
                      <div className={styles.footerAddressList}>
                        {addressList}
                      </div>
                    </AddressContentDiv>
                  </div>
                </StyledContentDiv>
              </Col>
              <Col lg={{ span: 3, offset: 0 }} md={{ span: 4, offset: 3 }}>
                <StyledContentDiv className={styles.contactSection}>
                  <Header>Contact</Header>
                  <div className={styles.contactInfoDiv}>
                    <AddressContentDiv className={styles.addressDiv}>
                      <AddressHeader>
                        TrustFlight {selectedLocation.name}
                      </AddressHeader>
                      <AddressText>{selectedLocation.phone}</AddressText>
                      <AddressText>
                        E:{" "}
                        <a href={`mailto:${selectedLocation.email}`}>
                          {selectedLocation.email}
                        </a>
                      </AddressText>
                    </AddressContentDiv>
                    <AddressContentDiv className={styles.addressDiv}>
                      <AddressText>{selectedLocation.address}</AddressText>
                      <AddressText>{selectedLocation.address2}</AddressText>
                      <AddressText>{selectedLocation.address3}</AddressText>
                      <AddressText>{selectedLocation.address4}</AddressText>
                    </AddressContentDiv>
                  </div>
                </StyledContentDiv>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={12} className={styles.finalInfo}>
                <div>
                  <FinalText>© {moment().format("YYYY")} TrustFlight</FinalText>
                </div>
                <div>
                  <FinalText>
                    Registered in England and Wales No. 10989885
                  </FinalText>
                </div>
                <div>
                  <a
                    href="https://www.facebook.com/TrustFlight"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    <StyledImg src={FBLogo} alt="facebook logo" />
                  </a>
                  <a
                    href="https://twitter.com/trustflight"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    <StyledImg src={TwitterLogo} alt="twitter logo" />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/trustflight/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.socialLink}
                  >
                    <StyledImg src={LILogo} alt="linkedin logo" />
                  </a>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </StyledDiv>
  );
};
