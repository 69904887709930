import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import styles from "./layout.module.css";
import { Helmet } from "react-helmet";

export default ({ children }) => {
  return (
    <div className={styles.mainLayout}>
     <Helmet defer={false}>
        <html lang="en" ontouchmove />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Helmet>
      <Header />
      {children}
      <Footer />
    </div>
  );
};
