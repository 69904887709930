import React, { Component, Fragment } from "react";
import { Link } from "gatsby";
import { Container, Navbar, Nav, Row, Col } from "react-bootstrap";
import { Drawer, Icon } from "antd";
import styled from "styled-components";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import isTouchDevice from "is-touch-device";
import logo from "../assets/trustflight-logo-white.svg";
import logoBlue from "../assets/trustflight-logo-blue.svg";
import styles from "./header.module.less";
import Tech from "../assets/product-tech-log.svg";
import Task from "../assets/product-task-cards.svg";
import MEL from "../assets/product-mel-manager.svg";
import Logbook from "../assets/product-digital-engine-logbook.svg";
import Registry from "../assets/product-aircraft-registry.svg";
import CAMO from "../assets/product-camo-services.svg";
import Centrik from "../assets/product-centrik.svg";
import DisplayCard from "./LandingPage/displayCard";
import ScrollAnimation from "react-animate-on-scroll";

const linkArray = [
  "products",
  "services",
  "technology",
  "about",
  "careers",
  "news",
];
const mobileLinkArray = [
  "products",
  "services",
  "technology",
  "about",
  "careers",
  "news",
  "contact",
];

const Button = styled.button`
  font-size: 13px;
  font-family: "Open Sans";
  border-radius: 20px;
  padding: 10px 25px;
  color: ${({ dropdownProductsVisible, dropdownServicesVisible }) =>
    dropdownProductsVisible || dropdownServicesVisible ? "#1E5BB5" : "#03D4FF"};
  background-color: transparent;
  font-weight: bold;
  border: ${({ dropdownProductsVisible, dropdownServicesVisible }) =>
    dropdownProductsVisible || dropdownServicesVisible
      ? "2px solid #1E5BB5"
      : "2px solid #03D4FF"};
  transition: all 0.2s;
  &:hover {
    color: #fff;
    border-color: #fff;
  }
  &:focus {
    outline: none;
  }
`;

const MainHeader = styled("div")`
  position: fixed;
  top: 0;
  width: 100%;
`;

const DropDown = styled("div")`
  position: relative;
  width: 100%;
  margin-bottom: -15px;
  background: #fff;
  box-shadow: 0px 20px 20px 0 #062a5559;
`;

const taskCard = {
  img: Task,
  title: "Electronic Task Cards",
  text:
    "Experience maintenance the modern way with interactive manuals, time-based validation and records automatically created as part of the process.",
  link: "taskcards",
};

const logbookCard = {
  img: Logbook,
  title: "Digital Engine Log",
  text:
    "The single-source of trusted flight records and associated flight data. Secured with blockchain.",
  link: "logbook",
};

const techCard = {
  img: Tech,
  title: "Electronic Tech Log",
  text:
    "Automated flight entry, intuitive defect recording and fully integrated with your existing scheduling and maintenance systems.",
  link: "techlog",
};

const melCard = {
  img: MEL,
  title: "MEL Manager",
  text:
    "Hassle-free MEL Management Platform to issue and revise MELs with just a few clicks.",
  link: "mel-manager",
};

const camoCard = {
  img: CAMO,
  title: "CAMO Services",
  text:
    "Technical expertise combined with the latest technologies enabling efficient aircraft maintenance.",
  link: "camo",
};

const registryCard = {
  img: Registry,
  title: "Aircraft Registry",
  text:
    "A new proposition for the registration of high-value private and corporate aircraft, the Registry places an emphasis on safety and professional expertise.",
  link: "registry",
};

const centrikCard = {
  img: Centrik,
  title: "Centrik",
  text:
    "Simple, intuitive operational management. Real-time integrated management of your operations.",
  link: "centrik",
};

const StyledDropdownHeaderText = styled.span`
  font-size: 12px;
  line-height: 1.57;
  font-weight: 400;
  letter-spacing: 0.02px;
  color: #9fa1a6;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
`;

const DropDownOptionWrapper = styled.div`
  padding-top: 20px;
`;

export default class Header extends Component {
  constructor() {
    super();
    this.state = {
      scrolled: false,
      dropdownProductsVisible: false,
      dropdownServicesVisible: false,
      drawerVisible: false,
    };
  }

  componentDidMount = () => {
    window.addEventListener("scroll", this.changeScrollHeader);
    this.setState({
      scrolled: false,
      dropdownProductsVisible: false,
      dropdownServicesVisible: false,
      drawerVisible: false,
    });
  };

  showDrawer = () => {
    this.setState({
      drawerVisible: true,
    });
  };

  onClose = () => {
    this.setState({
      drawerVisible: false,
    });
  };

  changeScrollHeader = () => {
    if (window.scrollY > 50) {
      this.setState({ scrolled: true });
    } else {
      this.setState({ scrolled: false });
    }
  };

  getMenuLinks = (type) => {
    const { dropdownProductsVisible, dropdownServicesVisible } = this.state;
    let useArray = linkArray;
    if (type === "mobile") {
      useArray = mobileLinkArray;
    }
    const allLinks = useArray.map((link) => {
      if (link === "products") {
        return (
          <Fragment>
            <Nav.Link
              onMouseEnter={() => {
                if (!isTouchDevice()) {
                  this.setState({
                    dropdownProductsVisible: true,
                    dropdownServicesVisible: false,
                  });
                }
              }}
              onClick={() => {
                this.setState({
                  dropdownProductsVisible: !dropdownProductsVisible,
                  dropdownServicesVisible: false,
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  dropdownProductsVisible: false,
                  dropdownServicesVisible: false,
                });
              }}
              className={`${styles.link} ${
                styles.linkParent
              } ${dropdownProductsVisible && styles.activeLinkBlue}`}
              key={link}
            >
              {link}{" "}
              {type !== "mobile" &&
                (dropdownProductsVisible ? <FaChevronUp /> : <FaChevronDown />)}
            </Nav.Link>
            {type === "mobile" && (
              <Fragment>
                <Link
                  to={`/${techCard.link}`}
                  className={`${styles.link} ${styles.subLink} ${styles.subLinkFirst}`}
                  activeClassName={styles.activeLink}
                  key={link}
                >
                  {techCard.title}
                </Link>
                <Link
                  to={`/${melCard.link}`}
                  className={`${styles.link} ${styles.subLink} ${styles.subLinkFirst}`}
                  activeClassName={styles.activeLink}
                  key={link}
                >
                  {melCard.title}
                </Link>
                <Link
                  to={`/${taskCard.link}`}
                  className={`${styles.link} ${styles.subLink}`}
                  activeClassName={styles.activeLink}
                  key={link}
                >
                  {taskCard.title}
                </Link>
                <Link
                  to={`/${logbookCard.link}`}
                  className={`${styles.link} ${styles.subLink}`}
                  activeClassName={styles.activeLink}
                  key={link}
                >
                  {logbookCard.title}
                </Link>
                <Link
                  to={`/${centrikCard.link}`}
                  className={`${styles.link} ${styles.subLink}`}
                  activeClassName={styles.activeLink}
                  key={link}
                >
                  {centrikCard.title}
                </Link>
              </Fragment>
            )}
          </Fragment>
        );
      } else if (link === "services") {
        return (
          <Fragment>
            <Nav.Link
              onMouseEnter={() => {
                if (!isTouchDevice()) {
                  this.setState({
                    dropdownServicesVisible: true,
                    dropdownProductsVisible: false,
                  });
                }
              }}
              onClick={() => {
                this.setState({
                  dropdownServicesVisible: !dropdownServicesVisible,
                  dropdownProductsVisible: false,
                });
              }}
              onMouseLeave={() => {
                this.setState({
                  dropdownServicesVisible: false,
                  dropdownProductsVisible: false,
                });
              }}
              className={`${styles.link} ${
                styles.linkParent
              } ${dropdownServicesVisible && styles.activeLinkBlue}`}
              key={link}
            >
              {link}{" "}
              {type !== "mobile" &&
                (dropdownServicesVisible ? <FaChevronUp /> : <FaChevronDown />)}
            </Nav.Link>
            {type === "mobile" && (
              <Fragment>
                {/* <Link
                  to={`/${registryCard.link}`}
                  className={`${styles.link} ${styles.subLink} ${styles.subLinkFirst}`}
                  activeClassName={styles.activeLink}
                  key={link}
                >
                  {registryCard.title}
                </Link> */}
                <Link
                  to={`/${camoCard.link}`}
                  className={`${styles.link} ${styles.subLink}`}
                  activeClassName={styles.activeLink}
                  key={link}
                >
                  {camoCard.title}
                </Link>
              </Fragment>
            )}
          </Fragment>
        );
      } else {
        return (
          <Link
            to={`/${link}`}
            className={`${styles.link} ${link === "contact" &&
              styles.linkContact}`}
            activeClassName={
              dropdownProductsVisible || dropdownServicesVisible
                ? styles.activeLinkBlue
                : styles.activeLink
            }
            key={link}
          >
            {link}
          </Link>
        );
      }
    });
    return allLinks;
  };

  render() {
    const {
      scrolled,
      dropdownProductsVisible,
      dropdownServicesVisible,
    } = this.state;

    return (
      <MainHeader
        className={`${styles.mainHeader} ${scrolled &&
          styles.scrolledHeader} ${(dropdownProductsVisible ||
          dropdownServicesVisible) &&
          window.innerWidth > 991 &&
          styles.headerDropdownActive}`}
      >
        <div className={styles.scrolledHeaderBg} />
        <Container className={styles.headerContainer}>
          <Navbar variant="dark" className={styles.tfNavbar}>
            <Navbar.Brand className={styles.navbarBrand}>
              <Link to="/">
                {(dropdownProductsVisible || dropdownServicesVisible) &&
                window.innerWidth > 991 ? (
                  <img src={logoBlue} alt="logo" className={styles.image} />
                ) : (
                  <img src={logo} alt="logo" className={styles.image} />
                )}
              </Link>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse
              className={`justify-content-end ${styles.navbarCollapse}`}
            >
              <Nav className="mr-auto">{this.getMenuLinks()}</Nav>
              <Navbar.Text>
                <Link to="/contact">
                  <Button
                    dropdownProductsVisible={dropdownProductsVisible}
                    dropdownServicesVisible={dropdownServicesVisible}
                  >
                    CONTACT
                  </Button>
                </Link>
              </Navbar.Text>
            </Navbar.Collapse>
            <button
              className={styles.collapseIcon}
              onClick={() => this.showDrawer()}
            >
              <Icon type="menu-fold" />
            </button>
          </Navbar>
        </Container>
        {dropdownProductsVisible && window.innerWidth > 991 && (
          <DropDown
            onMouseEnter={() => {
              this.setState({
                dropdownProductsVisible: true,
                dropdownServicesVisible: false,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                dropdownProductsVisible: false,
                dropdownServicesVisible: false,
              });
            }}
          >
            <Container>
              <Row>
                <Col md={4}>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    offset={0}
                    scrollableParentSelector={`.${styles.headerContainer}`}
                  >
                    <DropDownOptionWrapper>
                      <StyledDropdownHeaderText>
                        Operations
                      </StyledDropdownHeaderText>
                      <DisplayCard
                        noShadow
                        img={centrikCard.img}
                        title={centrikCard.title}
                        text={centrikCard.text}
                        link={centrikCard.link}
                        small
                      />
                      <DisplayCard
                        noShadow
                        img={melCard.img}
                        title={melCard.title}
                        text={melCard.text}
                        link={melCard.link}
                        small
                        padding
                      />
                    </DropDownOptionWrapper>
                  </ScrollAnimation>
                </Col>
                <Col md={4}>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    offset={0}
                    delay={150}
                    scrollableParentSelector={`.${styles.headerContainer}`}
                  >
                    <DropDownOptionWrapper>
                      <StyledDropdownHeaderText>
                        Airworthiness
                      </StyledDropdownHeaderText>
                      <DisplayCard
                        noShadow
                        img={techCard.img}
                        title={techCard.title}
                        text={techCard.text}
                        link={techCard.link}
                        small
                      />
                      <DisplayCard
                        noShadow
                        img={taskCard.img}
                        title={taskCard.title}
                        text={taskCard.text}
                        link={taskCard.link}
                        small
                      />
                    </DropDownOptionWrapper>
                  </ScrollAnimation>
                </Col>
                <Col md={4}>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    offset={0}
                    delay={300}
                    scrollableParentSelector={`.${styles.headerContainer}`}
                  >
                    <DropDownOptionWrapper>
                      <StyledDropdownHeaderText>
                        Aircraft and Parts Monitoring
                      </StyledDropdownHeaderText>
                      <DisplayCard
                        noShadow
                        img={logbookCard.img}
                        title={logbookCard.title}
                        text={logbookCard.text}
                        link={logbookCard.link}
                        small
                      />
                    </DropDownOptionWrapper>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Container>
          </DropDown>
        )}
        {dropdownServicesVisible && window.innerWidth > 991 && (
          <DropDown
            onMouseEnter={() => {
              this.setState({
                dropdownServicesVisible: true,
                dropdownProductsVisible: false,
              });
            }}
            onMouseLeave={() => {
              this.setState({
                dropdownServicesVisible: false,
                dropdownProductsVisible: false,
              });
            }}
          >
            <Container>
              <Row>
                {/* <Col md={4}>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    offset={0}
                    scrollableParentSelector={`.${styles.headerContainer}`}
                  >
                    <DropDownOptionWrapper>
                      <StyledDropdownHeaderText>
                        Aircraft Registry
                      </StyledDropdownHeaderText>
                      <DisplayCard
                        noShadow
                        img={registryCard.img}
                        title={registryCard.title}
                        text={registryCard.text}
                        link={registryCard.link}
                        small
                      />
                    </DropDownOptionWrapper>
                  </ScrollAnimation>
                </Col> */}
                <Col md={4}>
                  <ScrollAnimation
                    animateIn="fadeIn"
                    offset={0}
                    delay={150}
                    scrollableParentSelector={`.${styles.headerContainer}`}
                  >
                    <DropDownOptionWrapper>
                      <StyledDropdownHeaderText>
                        Continuing Airworthiness Management
                      </StyledDropdownHeaderText>
                      <DisplayCard
                        noShadow
                        img={camoCard.img}
                        title={camoCard.title}
                        text={camoCard.text}
                        link={camoCard.link}
                        small
                      />
                    </DropDownOptionWrapper>
                  </ScrollAnimation>
                </Col>
              </Row>
            </Container>
          </DropDown>
        )}
        <Drawer
          placement="right"
          closable={true}
          onClose={this.onClose}
          className={styles.drawerSideMenu}
          visible={this.state.drawerVisible}
        >
          <Nav className={styles.drawerNav}>{this.getMenuLinks("mobile")}</Nav>
        </Drawer>
      </MainHeader>
    );
  }
}
