import React from "react";
import styled from "styled-components";
import { Button, Row, Col } from "react-bootstrap";
import pageStyles from "../../pages/page.module.less";
import styles from "./started.module.less";
import GoArrow from "../../assets/go-arrow-blue.svg";
import ScrollAnimation from 'react-animate-on-scroll';

const StartedWrapper = styled.div`
  font-family: "Open Sans";
  text-align: center;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  padding-bottom: 100px;
  align-items: center;
  @media screen and (max-width: 576px) {
    padding-bottom: 60px;
  }
`;

const StartedHeader = styled.h3`
  font-size: 28px;
  font-weight: 600;
  letter-spacing: 0.04px;
  color: #242d41;
  padding-bottom: 20px;
  @media screen and (max-width: 1200px) {
    font-size: 24px;
  }
  @media screen and (max-width: 576px) {
    font-size: 22px;
  }
`;

const StartedText = styled.p`
  font-size: 18px;
  letter-spacing: 0.03px;
  color: #242d41;
  padding-bottom: 15px;
  font-weight: 300;
  @media screen and (max-width: 1200px) {
    font-size: 16px;
  }
`;

const Paperless = styled.span`
  font-size: 16px;
  letter-spacing: 0.02px;
  color: #242d41;
  padding: 0 10px;
  @media screen and (max-width: 576px) {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
  }
`;

const Touch = styled.span`
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.02px;
  color: #1E5BB5;
  padding: 0 8px;
  @media screen and (max-width: 576px) {
    font-size: 14px;
  }
`;

const StyledArrow = styled.img`
  height: 14px;
`;

export default props => {
  return (
    <Row className={`justify-content-md-center ${pageStyles.pageParentRow}`}>
      <Col xl={8} lg={10} md={10}>
        <StartedWrapper>
        <ScrollAnimation animateOnce animateIn="fadeInDown" offset={0}>
          <StartedHeader>{props.title || "Let's Get Started"}</StartedHeader>
          </ScrollAnimation>
          <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0}>
          <StartedText>
            {props.text ||
              "Talk to our sales team to find out how you can transform your operation with our products and services."}
          </StartedText>
          </ScrollAnimation>
          <ScrollAnimation animateOnce animateIn="fadeInUp" offset={0} delay={150}>
          <Button
            href={props.link || "/contact"}
            variant="link"
            className={styles.ctaWhite}
          >
            <Paperless>
              {props.buttonText || "Ready to go paperless?"}
            </Paperless>
            <Touch>{props.buttonTextStrong || "Get in Touch"}</Touch>
            <StyledArrow src={GoArrow} alt="arrow" className={styles.goArrow} />
          </Button>
          </ScrollAnimation>
        </StartedWrapper>
      </Col>
    </Row>
  );
};
