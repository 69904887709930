import React from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import GoArrow from "../../assets/icon-arrow.svg";
import GreenTick from "../../assets/icon-tick.svg";
import { Link } from "gatsby";
import styles from "./displayCard.module.less";

const StyledImage = styled.img`
  width: 90%;
  max-height: 100%;
  margin-top: 10px;
  margin-right: 20px;
  margin-left: 5%;
  transition: all 0.3s;
  @media screen and (max-width: 768px) {
    max-height: 130px;
  }
  @media screen and (max-width: 576px) {
    margin-bottom: 15px;
    max-height: 80px;
  }
`;

const ArrowImage = styled.img`
  height: 14px;
  ${props => props.small && "height: 12px;"}
  margin-left: 10px;
  margin-top: -2px;
  transition: all 0.4s;
`;

const Text = styled.p`
  font-size: 14px;
  line-height: 1.57;
  letter-spacing: 0.02px;
  color: #9fa1a6;
  margin-top: 8px;
  transition: all 0.4s;
  ${props => props.small && "font-size: 12px;"}
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
`;

const DisplayCard = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background-color: white;
    ${props => (props.ticks ? "width: 80%" : "width: 100%")};
    height: 100%;
    min-height: 150px;
    position: relative;
    box-shadow: 0 7px 19px 0 rgba(196, 200, 209, 0.64);
    border-radius: 4px;
    ${props => props.noShadow && "box-shadow: none;"}
    ${props => (props.noShadow ? "padding: 10px 0 10px 0;" : "padding: 20px;")}
    ${props => (props.noShadow ? "margin: 0;" : "margin: 20px 0;")}
    ${props =>
      props.bottomPage &&
      "box-shadow: 0 7px 19px 0 rgba(196, 200, 209, 0.64); padding: 30px 25px; margin: 0;"}
    ${props => props.padding && 'padding-bottom: 30px;'}
    &:hover ${StyledImage} {
        transform: scale(1.02);
    }
    &:hover ${ArrowImage} {
        transform: translateX(3px);
    }
    &:hover ${Text} {
        color: #666;
    }
`;

const TextDiv = styled.div`
  text-align: left;
`;

const Title = styled.span`
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.02px;
  color: #242d41;
  ${props => props.small && "font-size: 12px;"}
  @media screen and (max-width: 1200px) {
    font-size: 15px;
    ${props => props.small && "font-size: 12px;"}
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
    display: block;
  }
  @media screen and (max-width: 576px) {
    text-align: center;
  }
`;

const TickDiv = styled.div`
  border-left: 1px solid #f0f5f9;
  padding-left: 30px;
  font-size: 14px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  ${props => (props.display ? "display: block:" : "display: none;")};
  @media screen and (max-width: 991px) {
    padding-left: 15xpx;
  }
  @media screen and (max-width: 768px) {
    padding-left: 0;
    border-left: 0;
    padding-top: 12px;
  }
  @media screen and (max-width: 450px) {
    border-top: 1px solid #f0f5f9;
  }
`;

const StyledTickText = styled.span`
  font-size: 14px;
  line-height: 1.77;
  letter-spacing: 0.02px;
  color: #92969e;
  text-align: left;
  padding-left: 8px;
  @media screen and (max-width: 991px) {
    font-size: 13px;
  }
`;

const InnerTickDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: start;
  margin: 5px 0;
`;

const TickImage = styled.img`
  padding-top: 4px;
  width: 20px;
`;

export default props => {
  const ticktext = props.ticks
    ? props.ticks.map(tick => {
        return (
          <InnerTickDiv key={tick}>
            <TickImage src={GreenTick} alt="tick" />
            <StyledTickText>{tick}</StyledTickText>
          </InnerTickDiv>
        );
      })
    : null;
  return (
    <DisplayCard
      display={props.ticks}
      noShadow={props.noShadow}
      bottomPage={props.bottomPage}
      padding={props.padding}
    >
      <Link to={`/${props.link}`} className={styles.fullLink} />
      <Row className="align-items-md-center">
        <Col md={props.ticks ? 8 : 12} sm={12} className={styles.displayCardCol}>
          <Row>
            <Col md={4} sm={3} className={styles.colImageParent}>
              <StyledImage src={props.img} alt="icon" />
            </Col>
            <Col md={8} sm={12} className={styles.displayCardCol}>
              <TextDiv>
                <Title small={props.small}>
                  {props.title}{" "}
                  <ArrowImage src={GoArrow} alt="arrow" small={props.small} />
                </Title>
                <Row className={styles.mobileText}>
                  <Col sm={5}>
                    <StyledImage src={props.img} alt="icon" />
                  </Col>
                  <Col sm={7}>
                    <Text small={props.small}>{props.text}</Text>
                    <TickDiv display={props.ticks}>{ticktext}</TickDiv>
                  </Col>
                </Row>
                <Text small={props.small} className={styles.desktopText}>
                  {props.text}
                </Text>
              </TextDiv>
            </Col>
          </Row>
        </Col>
        <Col md={{ span: 4, offset: 0 }} className={styles.desktopText}>
          <TickDiv display={props.ticks}>{ticktext}</TickDiv>
        </Col>
      </Row>
    </DisplayCard>
  );
};
