import React, { Component, Fragment } from "react";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import goArrow from "../assets/icon-arrow-electric.svg";
import playIcon from "../assets/Landing/play-icon.svg";
import ModalVideo from "react-modal-video";
import Img from "gatsby-image";
import styles from "./pageHeader.module.less";
import * as Scroll from "react-scroll";
import ScrollAnimation from "react-animate-on-scroll";

const { scroller } = Scroll;

export default class pageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }
  render() {
    const {
      link,
      linkText,
      bigHeader,
      bigImage,
      useBgImage,
      mediumHeader,
      bgImg,
      imageWithShadow,
      longTag,
      svgImage,
      scroll,
      centrik
    } = this.props;
    return (
      <div
        className={`${styles.pageHeader} ${bigHeader &&
          styles.bigHeader} ${useBgImage &&
          styles.pageHeaderBg} ${mediumHeader &&
          styles.pageHeaderMedium} ${longTag && styles.longTag}
          ${centrik && styles.pageHeaderCentrik}`}
        style={
          bgImg && {
            backgroundImage: `url(${bgImg.childImageSharp.fluid.src})`
          }
        }
      >
        <Container>
          <Row>
            {bigHeader ? (
              <Fragment>
                <Col md={5} className={styles.headerTextCol}>
                  <ScrollAnimation animateOnce animateIn="fadeIn" offset={0}>
                    <div className={styles.flexParent}>
                      <div className={styles.tagDiv}>
                        {this.props.tag && (
                          <div className={styles.headerTag}>
                            {this.props.tagStrong && (
                              <span className={styles.headerTagStrong}>
                                {this.props.tagStrong}
                              </span>
                            )}
                            <span className={styles.headerTagNormal}>
                              {this.props.tag}
                            </span>
                          </div>
                        )}
                        {this.props.logo && (
                          <img src={this.props.logo} alt="logo" style={{ marginBottom: '20px' }} />
                        )}
                      </div>
                      <div className={styles.headerDiv, centrik && styles.headerDivCentrik}>
                        <h1
                          className={styles.header}
                          dangerouslySetInnerHTML={{
                            __html: this.props.header
                          }}
                        />
                      </div>
                      <div className={styles.subHeaderDiv}>
                        <h2
                          className={styles.subHeader}
                          dangerouslySetInnerHTML={{
                            __html: this.props.subHeader
                          }}
                        />
                      </div>
                      {link && linkText && (
                        <Row>
                          <Col className={styles.actionLinkHolder}>
                            {scroll ? (
                              <div
                              className={`${styles.actionLink} ${this.props
                                .whiteLink && styles.actionLinkWhite}`}
                              onClick={() => document.getElementById('signUpForm').scrollIntoView({ behavior: "smooth" })}
                              >
                                {linkText}
                                <span>
                                  <img src={goArrow} alt="goArrow" />
                                </span>
                              </div>
                            ) : (
                            <Link
                              to={link}
                              className={`${styles.actionLink} ${this.props
                                .whiteLink && styles.actionLinkWhite}`}
                            >
                              {linkText}
                              <span>
                                <img src={goArrow} alt="goArrow" />
                              </span>
                            </Link>
                            )}
                          </Col>
                        </Row>
                      )}
                    </div>
                  </ScrollAnimation>
                </Col>
                <Col md={7}>
                  <ScrollAnimation animateOnce animateIn="fadeIn" offset={0} style={{ height: '100%' }}>
                    <div className={styles.headerImage, centrik && styles.headerImageCentrik}>
                      {svgImage ? (
                        <img src={bigImage} alt="header image" className={imageWithShadow && styles.imageWithShadow, centrik && styles.centrikImage} />
                      ) : (
                        <Img
                        fluid={bigImage}
                        className={imageWithShadow && styles.imageWithShadow}
                        />
                      )}
                        </div>
                  </ScrollAnimation>
                </Col>
              </Fragment>
            ) : (
              <Fragment>
                <Col md={9}>
                  <ScrollAnimation
                    animateOnce
                    animateIn="fadeInDown"
                    offset={0}
                  >
                    <div className={styles.tagDiv}>
                      {this.props.tag && (
                        <div className={styles.headerTag}>
                          {this.props.tagStrong && (
                            <span className={styles.headerTagStrong}>
                              {this.props.tagStrong}
                            </span>
                          )}
                          <span className={styles.headerTagNormal}>
                            {this.props.tag}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className={styles.headerDiv}>
                      <h1
                        className={styles.header}
                        dangerouslySetInnerHTML={{ __html: this.props.header }}
                      />
                    </div>
                  </ScrollAnimation>
                  <div className={styles.subHeaderDiv}>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeInUp"
                      offset={0}
                    >
                      <h2 className={styles.subHeader}>
                        {this.props.subHeader}
                      </h2>
                    </ScrollAnimation>
                  </div>
                </Col>
                {link && linkText && (
                  <Col md={3} className={styles.actionLinkHolder}>
                    <ScrollAnimation
                      animateOnce
                      animateIn="fadeIn"
                      offset={0}
                      delay={200}
                    >
                      <Link
                        to={link.indexOf("#positions") > -1 ? '/careers' : link}
                        className={styles.actionLink}
                        onClick={() => {
                          link.indexOf("#") > -1 &&
                            scroller.scrollTo(link.replace(/^.+#/, ""), {
                              duration: 800,
                              smooth: true,
                              offset: -60
                            });
                        }}
                      >
                        {linkText}
                        <span>
                          <img src={goArrow} alt="goArrow" />
                        </span>
                      </Link>
                    </ScrollAnimation>
                  </Col>
                )}
              </Fragment>
            )}
          </Row>
        </Container>
      </div>
    );
  }
}
